<template>
    <div class="peopleGoods">
        <!-- 关联到人数 -->
        <div class="title">关联到人数</div>
        <div class="between">
            <div class="searchForm">
                <span>查询商品：</span>
                <a-input v-model="searchForm.keywords" placeholder="请输入商品名称/条码" @pressEnter="searchHandle"/>
                <a-button class="searchBtn" @click="searchHandle">查询</a-button>
            </div>
            <div class="searchForm">
                <a-button class="searchBtn" @click="showAdd = true">新增关联</a-button>
            </div>
        </div>
        <a-table :columns="columns" :data-source="tableData" :rowKey="(item,index) => index" @change="handleTableChange"
        :pagination="{ current:searchForm.page, total:total ,  pageSize: searchForm.listRows}">
            <a slot="handle" slot-scope="text,record">
                <span @click="deleteHandle(record)">删除</span>
            </a>
        </a-table>
        <peopleGoodsAdd :showModal="showAdd" @closeHandle="showAdd = false" @saveOk="showAdd = false ; searchHandle()"></peopleGoodsAdd>
    </div>
</template>
<script>
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import peopleGoodsAdd from './peopleGoodsAdd.vue'
import { getGoodsPeopleList , deleteGoodsPeopleList } from '@/request/peopleGoods'
export default {
    name:'peopleGoods',
    data(){
        return{
            searchForm:{
                listRows:15,
                page:1,
                keywords:''
            },
            total:0,
            tableData:[],
            columns:[
                {
                    title: '商品名称',
                    dataIndex: 'goods_name',
                    align:'center'
                },
                {
                    title: '商品条码',
                    dataIndex: 'goods_no',
                    align:'center'
                },
                {
                    title: '规格',
                    dataIndex: 'spec_name',
                    align:'center'
                },
                {
                    title: '单位',
                    dataIndex: 'goods_unit',
                    align:'center'
                },
                {
                    title: '零售价',
                    dataIndex: 'goods_price',
                    align:'center'
                },
                {
                    title: '操作',
                    dataIndex: 'handle',
                    scopedSlots: { customRender: 'handle' },
                    align:'center'
                },
            ],
            showAdd:false,
            keywords:''
        }
    },
    components: {
        breadCrumb,peopleGoodsAdd
    },
    mounted(){
        this.getTableList()
    },
    methods:{
        getTableList(){
            getGoodsPeopleList(this.searchForm).then(res => {
                if(res.data.status == 200){
                    this.tableData = res.data.data.data.data
                    this.total = res.data.data.data.total
                }
            })
        },
        searchHandle(){
            this.searchForm.page = 1
            this.getTableList()
        },
        deleteHandle(row){
            deleteGoodsPeopleList({id:row.id}).then(res => {
                if(res.data.status == 200){
                    this.$message.success('删除成功')
                    this.getTableList()
                }
            })
        },
        handleTableChange(e){ //点击分页
            this.searchForm.page = e.current;
            this.getTableList()
        },
    }
}
</script>
<style lang="less" scoped>
.peopleGoods{
    padding: 1vh 1vw;
    .title{
        font-size: 2.1vh;
        padding: 1.5vh 0;
        font-weight: 700;
    }
    .between{
        display: flex;
        justify-content: space-between;
    }
}
::v-deep .ant-table-thead{
    th{
        padding: 1.5vh 1vw;
        font-size: 1.8vh; 
    }
}
::v-deep .ant-table-row{
    td{
        padding: 1.3vh 1vw;
        font-size: 1.8vh;
    }
}
</style>