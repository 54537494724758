import request from "@/request/request";


export function getGoodsPeopleList(data) {     //获取关联人数商品列表
    return request({
        url: "/clerk/goods/getGoodsPeopleList",
        method: "post",
        data: data,
    });
}

export function saveGoodsBindPeople(data) {     //新增 关联人数商品
    return request({
        url: "/clerk/goods/saveGoodsBindPeople",
        method: "post",
        data: data,
    });
}

export function deleteGoodsPeopleList(data) {     //删除 关联人数商品
    return request({
        url: "/clerk/goods/deleteGoodsPeopleList",
        method: "post",
        data: data,
    });
}