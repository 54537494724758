<template>
    <div class="peopleGoodsAdd">
        <a-modal :visible="showModal" title="新增关联" width="62vw" @cancel="handleCancel">
            <template slot="footer">
                <div style="text-align: center;">
                    <a-button class="clearBtn" @click="handleCancel">取消</a-button>
                    <a-button class="OkBtn" @click="handleOk">确定</a-button>
                </div>
            </template>
            <div class="listData">
                <div>
                    <div class="title">选择商品</div>
                    <div class="searchForm">
                        <a-input v-model="keywords" placeholder="请输入商品名称/条码" @pressEnter="getList"/>
                        <a-button class="searchBtn" @click="getList">查询</a-button>
                    </div>
                    <div class="left_item" v-for="(item,i) in goodsData" :key="i">
                        <a-checkbox v-model="item.checked">
                            <span class="goodsname">{{item.goods_name}}</span>
                            <!-- <span class="detail" v-if="item.spec_type == 20">{{item.spec_content}}/</span> -->
                            <span class="detail" v-if="item.spec_type == 20">
                                <span v-for="ele in item.spec_content" :key="ele.goods_no">
                                    {{ele.goods_no == item.new_goods_no ? '￥' + (ele.spec_price * 1).toFixed(2) : ''}}
                                </span>
                            </span>
                            <span class="detail" v-else>￥{{item.goods_price}}</span>
                        </a-checkbox>
                        <a-radio-group style="margin:1vh 0 2vh;" v-if="item.spec_type == 20 && item.checked" v-model="item.new_goods_no" :options="item.spec_content"/>
                    </div>
                </div>
                <div>
                    <div class="title">关联到人数</div>
                    <div v-for="(item,i) in goodsData" :key="i">
                        <div class="left_item" v-if="item.checked">
                            <a-checkbox v-model="item.checked">
                                <span class="goodsname">{{item.goods_name}}</span>
                                <span class="detail" v-if="item.spec_type == 20">
                                    <span v-for="ele in item.spec_content" :key="ele.goods_no">
                                        {{ele.goods_no == item.new_goods_no ? ele.spec_name : ''}}
                                        {{ele.goods_no == item.new_goods_no ? '￥' + (ele.spec_price * 1).toFixed(2) : ''}}
                                    </span>
                                </span>
                                <span class="detail" v-else>￥{{item.goods_price}}</span>
                            </a-checkbox>
                        </div>
                    </div>
                    
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import { goodsList } from '@/request/business'
import { saveGoodsBindPeople } from '@/request/peopleGoods'
export default {
    name:'peopleGoodsAdd',
    props:{
        showModal:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        showModal(val){
            if(val){
                this.getList()
            }
        }
    },
    data(){
        return{
            keywords:'',
            goodsData:[]
        }
    },
    methods:{
        getList(){
            goodsList({noPage:'yes',keywords:this.keywords,is_relation_people:1}).then(res => {
                console.log(res);
                if(res.data.status == 200){
                    let data = res.data.data && res.data.data.list
                    data.forEach(item => {
                        item.checked = false
                        if(item.spec_type == 20){
                            item.spec_content = JSON.parse(item.spec_content)
                            item.spec_content.forEach(ele => {
                                ele.value = ele.goods_no
                                ele.label = ele.spec_name
                            })
                            item.new_goods_no = item.spec_content[0].goods_no
                        }
                    });
                    this.goodsData = data
                }
            })
        },
        handleCancel(){
            this.$emit('closeHandle')
        },
        handleOk(){
            let list = []
            this.goodsData.forEach(item => {
                if(item.checked){
                    if(item.spec_type == 20){
                        list.push({
                            goods_id:item.goods_id,
                            spec_type:item.spec_type,
                            goods_no:item.new_goods_no,
                        })
                    }else{
                        list.push({
                            goods_id:item.goods_id,
                            spec_type:item.spec_type,
                            goods_no:item.goods_no,
                        })
                    }
                }
            })
            saveGoodsBindPeople({content: list}).then(res => {
                if(res.data.status == 200){
                    this.$message.success('新增成功')
                    this.$emit('saveOk')
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.listData{
    display: flex;
    border:1px solid #a6a6a6;
    >div{
        flex: 1;
        padding: 1.2vh 1vw; 
        height: 62vh;
        overflow: auto;
        .title{
            font-size: 2vh;
            text-align: center;
            font-weight: 700;
        }
        &:first-child{
            border-right: 1px solid #a6a6a6;
        }
        &::-webkit-scrollbar {
            width : 3px; 
            height: 3px;
        }
        &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 3px;
            background   : #a6a6a6;
        }
        &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            border-radius: 3px;
            background   : #e5e5e5;
        }
        .left_item{
           .goodsname{
                font-size: 2vh;
                margin-right: 1vw;
            }
            .detail{
                // padding: 0 0.2vw;
                font-size: 1.6vh;
                color: #a6a6a6;
            } 
            &:hover{
                background: #ffebe8;
            }
        }
        
    }
}
::v-deep .ant-checkbox-wrapper{
    width: 100%;
    padding: 1vh 1vw;
}

</style>